* {
  scrollbar-width: thin;
  scrollbar-color: gray #fff;
}

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 10px;
  border: 3px solid #fff;
}
